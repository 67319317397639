import React, {useEffect} from 'react';
import {compose} from 'recompose';
import {inject, observer} from 'mobx-react';
import {navigate} from "gatsby";
import ListOutlinedIcon from '@material-ui/icons/ListOutlined';
import Button from '@material-ui/core/Button';
import { withStyles } from '@material-ui/core/styles';

import withRoot from '../withRoot';
import MobileHeadBar from '../components/mobile/MobileHeadBar';
import {ReactComponent as FilterIcon} from '@src/assets/svgIcons/Filter 1.svg';
import ExploreMap from '@src/components/map/ExploreMap';
import LoginModal from '@src/components/LoginModal';
import MobileFilter from '@src/components/mobile/MobileFilter';
import SearchBar from '@src/components/mobile/SearchBar';

import '../components/mobile/MobileLayout.less';

const ExploreMapView = ({uiStore, filterStore, propertyStore})=> {
  const {openLogin,setOpenLogin} = uiStore;
  const {setShowMobileFilter} = filterStore;
  const {getMapProperties_  } = propertyStore;

  useEffect(() => {
    uiStore.setExplorePageCurrentTab('explore')
  }, [])

  const onSearch = () => {
    getMapProperties_();
  }

  const ColorButton = withStyles((theme) => ({
    root: {
      color: 'white',
      backgroundColor: '#E56E2C',
      width: '100%'
    },
  }))(Button);

  return (
    <div>
      <MobileHeadBar />
      <div className='m-search-filter'>
        <SearchBar searchButtonClick={onSearch}/>
        <div className='filter-sort'>
          <div className='item filter' onClick={() => setShowMobileFilter(true)}>
            <FilterIcon height={15} width={16}/>
            <span style={{marginLeft: 6}}>Filter</span>
          </div>
          <div className='item' onClick={() => navigate('/exploreList')}>
            <ListOutlinedIcon height={13.33} width={16}/>
            <span style={{marginLeft: 6}}>List</span>
          </div>
        </div>
      </div>
      <div style={{position:'relative', overflow: 'hidden', height:'calc(100vh - 180px)'}}>
        <ExploreMap />
      </div>
      {/* <div className='search-area'>
        <div className='label'>Your map positoin has changed</div>
        <ColorButton variant="contained" disableElevation className='search-btn'>Search this area</ColorButton>
      </div> */}
      <LoginModal visible={openLogin} handleCancel={()=>setOpenLogin(false)} />
      <MobileFilter />
    </div>
  )
};

export default compose(withRoot, inject('uiStore', 'filterStore', 'propertyStore'), observer)(ExploreMapView)